import axios from "axios";

const secretKey = 987654321;
const modulus = 1000000007;

axios.interceptors.request.use(
  (config) => {
    console.log("signature");
    const timestamp = Math.floor(Date.now() / 1000);

    const signature = ((timestamp ^ secretKey) + secretKey) % modulus;

    if (config.headers) {
      config.headers["X-Nonce"] = timestamp;
      config.headers["X-Signature"] = signature;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
